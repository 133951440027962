/* stylelint-disable selector-not-notation */

.MetaDatePicker :global {
    --price-level-green: #92e9d4;
    --price-level-yellow: #fdd28c;
    --price-level-red: #fcb4c0;

    .date-picker__modal {
        max-width: 800px;

        &--small-screen {
            padding-bottom: 1rem;
        }
    }

    &--single-month {
        max-width: 400px;
    }

    .date-picker__day {
        aspect-ratio: 1 / 1.2;
        justify-content: space-between;
        padding: 0.5rem;

        &--medium-screen {
            aspect-ratio: 1 / 1.3;
        }

        &--small-screen {
            aspect-ratio: 1 / 1.15;
        }

        &:not(:disabled):not(
                .date-picker__day--expired,
                .date-picker__day--start,
                .date-picker__day--between,
                .date-picker__day--non-current,
                .date-picker__day--end
            ) {
            background-color: var(--blue-primary-subtle);

            &:hover {
                border-color: #0063fb;
            }
        }

        &--green:not(:disabled):not(
                .date-picker__day--expired,
                .date-picker__day--start,
                .date-picker__day--between,
                .date-picker__day--non-current,
                .date-picker__day--end
            ) {
            background-color: var(--price-level-green);

            &:hover {
                border-color: #1d7454;
            }

            &:focus-visible:not(:is(:disabled, .date-picker__day--expired)) {
                outline: 2px solid #1d7454;
                outline-offset: -2px;
            }
        }

        &--yellow:not(:disabled):not(
                .date-picker__day--expired,
                .date-picker__day--start,
                .date-picker__day--between,
                .date-picker__day--non-current,
                .date-picker__day--end
            ) {
            background-color: var(--price-level-yellow);

            &:hover {
                border-color: #9b621e;
            }

            &:focus-visible:not(:is(:disabled, .date-picker__day--expired)) {
                outline: 2px solid #9b621e;
                outline-offset: -2px;
            }
        }

        &--red:not(:disabled):not(
                .date-picker__day--expired,
                .date-picker__day--start,
                .date-picker__day--between,
                .date-picker__day--non-current,
                .date-picker__day--end
            ) {
            background-color: var(--price-level-red);

            &:hover {
                border-color: #9e2216;
            }

            &:focus-visible:not(:is(:disabled, .date-picker__day--expired)) {
                outline: 2px solid #9e2216;
                outline-offset: -2px;
            }
        }
    }
}
